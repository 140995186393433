<template>
  <my-form
      v-model="data"
      resource="projeto-pesquisa/solicitacao"
      submitRedirect="/projeto-pesquisa/solicitacao"
      title="Cadastrar Solicitação de Projeto"
      @validate="errors = $event"
  >
    <v-card-title>
      Dados do Projeto
    </v-card-title>
    <v-card-text>
      <v-row>
        <v-col cols="12">
          <my-text-field
              v-model="data.project_name"
              label="Nome"
              :error-messages="errors.project_name"
              required
              @input="errors.project_name = []"
          />
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-title>
      Solicitante
    </v-card-title>
    <v-card-text>
      <v-row>
        <v-col cols="12" md="5">
          <my-text-field
              v-model="data.owner_name"
              label="Nome"
              :error-messages="errors.owner_name"
              required
              @input="errors.owner_name = []"
          />
        </v-col>
        <v-col cols="12" md="3">
          <my-text-field
              v-model="data.owner_position"
              label="Cargo"
              :error-messages="errors.owner_position"
              required
              @input="errors.owner_position = []"
          />
        </v-col>
        <v-col cols="12" md="4">
          <my-text-field
              v-model="data.owner_email"
              label="E-mail"
              placeholder="email@exemplo.com.br"
              :error-messages="errors.owner_email"
              required
              @input="errors.owner_email = []"
          />
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-title>
      Parceiro
    </v-card-title>
    <v-card-text>
      <v-row>
        <v-col cols="12" md="4">
          <my-text-field
              v-model="data.partner_name"
              label="Nome"
              :error-messages="errors.partner_name"
              @input="errors.partner_name = []"
          />
        </v-col>
        <v-col cols="12" md="2">
          <my-text-field
              v-model="data.partner_contact"
              label="Contato"
              :error-messages="errors.partner_contact"
              @input="errors.partner_contact = []"
          />
        </v-col>
        <v-col cols="12" md="2">
          <my-text-field
              v-model="data.partner_phone"
              label="Telefone"
              :error-messages="errors.partner_phone"
              mask="(##) #####-####"
              placeholder="(00) 00000-0000"
              @input="errors.partner_phone = []"
          />
        </v-col>
        <v-col cols="12" md="4">
          <my-text-field
              v-model="data.partner_site"
              label="Site"
              :error-messages="errors.partner_site"
              placeholder="https://www.exemplo.com.br"
              @input="errors.partner_site = []"
          />
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-title>
      Detalhes do Projeto
    </v-card-title>
    <v-card-text>
      <v-row>
        <v-col cols="12">
          <my-text-area
              v-model="data.tsp.description"
              label="Descrição do Produto"
              required
              :error-messages="errors['tsp.description']"
          />
        </v-col>
        <v-col cols="12">
          <my-text-area
              v-model="data.tsp.goal"
              label="Objetivos da Avaliação"
              required
              :error-messages="errors['tsp.goal']"
          />
        </v-col>
        <v-col cols="12">
          <my-text-area
              v-model="data.tsp.additional_information"
              label="Informações Adicionais"
              :error-messages="errors['tsp.additional_information']"
          />
        </v-col>
      </v-row>
    </v-card-text>
  </my-form>
</template>

<script>
import MyTextField from "@/view/components/MyTextField.vue";
import MyTextArea from "@/view/components/MyTextArea.vue";
import MyForm from "@/view/components/MyForm.vue";
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";

export default {
  name: 'PesquisaSolicitacaoProjetoCreateEdit',

  components: {MyForm, MyTextArea, MyTextField},

  props: {
    resource: {
      type: String,
      default: '',
    },
  },

  data: () => ({
    data: {
      project_name: null,
      owner_name: null,
      owner_position: null,
      owner_email: null,
      partner_name: null,
      partner_contact: null,
      partner_phone: null,
      partner_site: null,
      tsp: {
        description: null,
        goal: null,
        additional_information: null,
      }
    },
    errors: [],
  }),

  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      {title: 'Pesquisa'},
      {title: 'Solicitação de Projeto'},
    ])
  },
}
</script>